import React, {useContext, useEffect, useState} from 'react';
import tw, {styled} from 'twin.macro';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import data from '../../../config/site_data.json';

import {Container, Section} from '../../Grid';
import {Heading} from '../../Typography';

import {Button} from '../../Button';
import SuccessMessage from '../../SuccessMessage';
import FormConsent from '../../FormConsent';
import {inputStyles} from '../../Contact/ContactForm';

import {TranslateUrlsContext} from 'gatsby-plugin-translate-urls';

import {initForm} from '../../../utils';

const Wrapper = styled(Section)``;

const StyledContainer = styled(Container)`
  max-width: 480px;
`;
const Title = styled(Heading)`
  ${tw`text-secondary mb-9`}

  font-size: 33px;
  line-height: 133%;
  text-align: center;
  letter-spacing: -0.01em;
`;

const FormWrapper = styled.form`
  ${tw`flex w-full border border-100 focus-within:border-primary transition-border-color items-center`}
  border-radius: 11px;
  padding: 5px;
`;

const FormControl = styled.label`
  ${tw`flex-grow relative`}
`;

const Input = styled.input<{error?: boolean}>`
  ${tw`border-0 w-full`}

  ${inputStyles}
  border: none !important;
  outline: none;
  box-shadow: none;
`;

const FormButton = styled(Button)`
  height: 47px;
  min-width: 110px;

  @media (max-width: 380px) {
    white-space: nowrap;
    font-size: 12px;
  }
`;

const SuccessWrapper = styled.div`
  margin: 30px 0;
  ${tw`text-center`}

  span {
    ${tw`block mt-4 text-200`}
    font-size: 14px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const EmailForm = ({hideWeContactYou}: {hideWeContactYou?: boolean}) => {
  const {title, email_placeholder, button_text} = data.homepage.contact_form;
  const {send_again} = data.contact.success;
  const {t} = useTranslation();
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [formSubmittedOnce, setFormSubmittedOnce] =
    React.useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const form = document.getElementById('EmailForm');

    form?.addEventListener('kwSubmitted', () => {
      setFormSubmitted(true);
      setFormSubmittedOnce(true);
      setLoading(false);
    });
  }, [formSubmitted]);

  useEffect(() => {
    if (formSubmittedOnce && !formSubmitted) {
      initForm();
    }
  }, [formSubmitted]);

  const {locale} = useContext(TranslateUrlsContext);

  return (
    <Wrapper>
      <StyledContainer>
        <Title>{t(title)}</Title>
        {formSubmitted ? (
          <SuccessWrapper data-id="success-message-newsletter-form">
            <SuccessMessage hideWeContactYou={hideWeContactYou} />
            <span onClick={() => setFormSubmitted(false)}>{t(send_again)}</span>
          </SuccessWrapper>
        ) : (
          <FormWrapper
            className="kwes-form"
            action="https://kwes.io/api/foreign/forms/vwVzRBGD3bWe9xHK0dhc"
            data-kw-no-reload
            id="EmailForm"
            data-kw-lang={locale}
            autoComplete="off"
          >
            <input type="hidden" name="locale" value={locale} />
            <FormControl>
              <Input
                type="email"
                placeholder={t(email_placeholder)}
                name="email"
                data-kw-rules="required|email"
              />
            </FormControl>
            <FormButton type="submit" disabled={loading} loading={loading}>
              {t(button_text)}
            </FormButton>
          </FormWrapper>
        )}
        <FormConsent className="text-center mt-4" type="CHECKBOX_FORM" />
      </StyledContainer>
    </Wrapper>
  );
};

export default EmailForm;
