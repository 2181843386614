import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useContext,
} from 'react';
import {Context} from '../../../state/store';
import tw, {styled} from 'twin.macro';
import Glide from 'react-glidejs';
import {useTranslation} from 'react-i18next';

const OffersContainer = styled.div`
  ${tw`mb-5`}

  margin-top: 50px;

  @media ${props => props.theme.screens.md} {
    margin-top: 75px;
  }

  @media ${props => props.theme.screens.lg} {
    margin-top: 100px;
  }

  .glide__slide {
    height: unset !important;
  }
`;

const Card = styled.article`
  background: #ffffff;
  box-shadow: 10.008px 13.3441px 17.7921px -7.78403px rgba(0, 0, 0, 0.05);
  border-radius: 16.6801px;
  max-width: 272px;
  cursor: pointer;
  height: 100%;
  margin: 0 auto;
  padding: 20px 27px;
  position: relative;

  @media ${props => props.theme.screens.md} {
    max-width: 339px;
    padding: 38px 24px;
  }

  @media ${props => props.theme.screens.xl} {
    padding: 24px 32px;
    max-width: 380px;
  }
`;

const Title = styled.span`
  ${tw`text-secondary`}

  font-size: 18.9041px;
  line-height: 127%;
  @media ${props => props.theme.screens.xl} {
    font-size: 24px;
    line-height: 34px;
  }
  display: inline-block;
  margin-bottom: 30px;
`;

const Book = styled.span`
  ${tw`block text-200 uppercase`}
  position: absolute;
  bottom: 20px;
  right: 27px;

  @media ${props => props.theme.screens.md} {
    right: 24px;
  }

  @media ${props => props.theme.screens.xl} {
    right: 32px;
  }
`;

const Navigation = styled.div`
  ${tw`flex items-center justify-center`}

  margin-top: 22px;
`;

const Dots = styled.div`
  ${tw`flex  mx-4 md:mx-6`}
`;

const Dot = styled.div<{selected: boolean}>`
  ${tw`rounded-full cursor-pointer hover:bg-primary transition-colors`}

  background-color: #A7B7B5;

  width: 9px;
  height: 9px;

  &:not(:last-child) {
    margin-right: 7px;
  }

  background-color: ${props =>
    props.selected ? props.theme.colors.primary : ''};

  @media ${props => props.theme.screens.md} {
    width: 11px;
    height: 11px;

    &:not(:last-child) {
      margin-right: 11px;
    }
  }
`;

const NavComponent: React.FC<{
  items: [any];
  sliderRef: any;
  handleSliderButton: Function;
}> = forwardRef((props, ref) => {
  const [sliderIndex, setIndex] = useState(0);

  useImperativeHandle(ref, () => ({
    changeIndex() {
      if (props.sliderRef.current) {
        setIndex(props.sliderRef.current.index);
      }
    },
  }));

  const {items, handleSliderButton} = props;

  return (
    <Navigation>
      <Dots>
        {items.map((item: any, index: number) => {
          return (
            <Dot
              key={item.node.id}
              data-slide-direction={`=${index}`}
              onClick={handleSliderButton}
              selected={sliderIndex === index}
            />
          );
        })}
      </Dots>
    </Navigation>
  );
});

const Offers: React.FC<{offers: any}> = ({offers}) => {
  const {t} = useTranslation();

  const [_, dispatch] = useContext(Context);

  const ref = useRef({
    slider: React.createRef<HTMLElement>(),
    navigation: React.createRef<HTMLElement>(),
  });

  const handleSliderButton = (event: any) => {
    if (ref!.current!.navigation!.current) {
      ref!.current!.navigation!.current!.changeIndex();
    }

    ref!.current!.slider!.current!.go(event.target.dataset.slideDirection);
  };

  const handleSliderUpdate = () => {
    if (ref!.current!.navigation!.current) {
      ref!.current!.navigation!.current!.changeIndex();
    }
  };

  const handleBookNow = (promocode: string) => {
    if (promocode) {
      dispatch({type: 'SET_PROMOCODE', payload: promocode});
      dispatch({type: 'TOGGLE_DRAWER'});
    }
  };

  return (
    <OffersContainer>
      <Glide
        hideArrows
        ref={ref.current.slider}
        type="carousel"
        autoplay={6000}
        perView={3}
        startAt={0}
        focusAt={0}
        onSwipeEnd={handleSliderUpdate}
        onRun={handleSliderUpdate}
        breakpoints={{
          768: {
            perView: 1,
          },
          976: {
            perView: 2,
          },
        }}
      >
        {offers.map(({node}: any) => {
          const {id, title, promocode} = node;
          return (
            <Card key={id}>
              <Title>{t(title)}</Title>
              <Book onClick={() => handleBookNow(promocode)}>
                {t('book_now')}
              </Book>
            </Card>
          );
        })}
      </Glide>
      <NavComponent
        ref={ref.current.navigation}
        items={offers}
        handleSliderButton={handleSliderButton}
        sliderRef={ref.current.slider}
      />
    </OffersContainer>
  );
};

export default Offers;
