import React, {useContext} from 'react';
import {Context} from '../../../state/store';
import tw, {styled} from 'twin.macro';
import data from '../../../config/site_data.json';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Container, Section} from '../../Grid';
import {Heading} from '../../Typography';
import {Button} from '../../Button';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import {TranslatedLink} from 'gatsby-plugin-translate-urls';

const ServicesWrapper = styled(Section)`
  margin-top: 46px;
`;

const StyledHeading = styled(Heading)`
  ${tw`text-200 mb-5 lg:mb-12`}

  letter-spacing: -0.01em;

  max-width: 294px;

  span {
    ${tw`text-secondary`}
  }

  @media ${props => props.theme.screens.md} {
    margin-top: 90px;
    max-width: 521px;
    margin-left: 0;
  }

  @media ${props => props.theme.screens.lg} {
    max-width: 700px;
    margin-bottom: 90px;
  }
  /* @media ${props => props.theme.screens.xl} {
    max-width: 800px;
  } */
`;

const ServicesWraper = styled.div`
  ${tw`grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-x-7 lg:gap-y-7`}
`;
const ContentWrapper = styled.div`
  ${tw`absolute left-0 top-0 w-full h-full`};
  padding: 18px 23px;

  @media ${props => props.theme.screens.md} {
    padding: 49px 57px;
  }

  @media ${props => props.theme.screens.lg} {
    padding: 40px 47px;
  }
  @media ${props => props.theme.screens.xl} {
    padding: 49px 57px;
  }
`;

const Title = styled.h3`
  ${tw`text-secondary mb-3 md:mb-7 xl:mb-8`}
  max-width: 80%;

  @media ${props => props.theme.screens.md} {
    font-size: 30px;
    line-height: 42px;
    max-width: 50%;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 30px;
    line-height: 42px;
    max-width: 60%;
  }

  @media ${props => props.theme.screens.xl} {
    font-size: 39px;
    line-height: 125.5%;
    max-width: 60%;
  }

  font-size: 24px;
  line-height: 32px;
`;

const Item = styled.div`
  ${tw`relative`}

  padding-bottom: 62%;
  border-radius: 12px;
  overflow: hidden;

  margin: 0 auto;

  width: 100%;
  max-width: 611px;

  &:nth-child(2) {
    ${Title} {
      max-width: 150px;
    }
  }
  &:nth-child(3) {
    ${Title} {
      max-width: 150px;
    }
  }
  &:nth-child(6) {
    ${Title} {
      max-width: 150px;
    }
  }

  @media ${props => props.theme.screens.md} {
    padding-bottom: 52.84779050736497%;

    &:nth-child(4) {
      ${Title} {
        max-width: 300px;
      }
    }
    &:nth-child(5) {
      ${Title} {
        max-width: 300px;
      }
    }
  }

  @media ${props => props.theme.screens.lg} {
    border-radius: 24px;
    padding-bottom: 59%;
  }

  @media ${props => props.theme.screens.xl} {
    &:nth-child(2) {
      ${Title} {
        max-width: 203px;
      }
    }
    &:nth-child(3) {
      ${Title} {
        max-width: 203px;
      }
    }
    &:nth-child(4) {
      ${Title} {
        max-width: 352px;
      }
    }
    &:nth-child(5) {
      ${Title} {
        max-width: 268px;
      }
    }
    &:nth-child(6) {
      ${Title} {
        max-width: 212px;
      }
    }
    &:nth-child(7) {
      ${Title} {
        max-width: 355px;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  ${tw`absolute w-full h-full left-0 top-0 z--1`}
`;

const ButtonGroup = styled.div`
  ${tw`inline-flex flex-col items-center`}
`;

const BookButton = styled(Button)`
  ${tw`px-5 `}
  font-size: 16px;
  height: 42px;

  @media ${props => props.theme.screens.md} {
    font-size: 18px;
    line-height: 139.02%;
    height: 52px;
    margin-bottom: 10px;
  }

  @media ${props => props.theme.screens.xl} {
    padding-left: 35px;
    padding-right: 35px;
  }
`;

const LinkButton = styled(Button)`
  ${tw`bg-transparent text-secondary uppercase px-3`}
  line-height: 139.02%;
  height: 42px;
  font-size: 13px;
  letter-spacing: 0.075em;
  @media ${props => props.theme.screens.lg} {
    font-size: 12px;
    line-height: 139.02%;
    height: 52px;
  }
  @media ${props => props.theme.screens.xl} {
    font-size: 14px;
  }
`;

const Copy = styled.p`
  ${tw`font-medium text-secondary`}
  font-size: 15px;
  line-height: 24px;
  strong {
    ${tw`block mb-4`}

    @media ${props => props.theme.screens.md} {
      font-size: 19px;
      line-height: 31px;
    }
  }
`;

const WhiteBg = styled.div`
  background: #f2f1f1;
  position: absolute;
  inset: 0;
  right: 60%;
`;

const WhiteBgTransparent = styled.div`
  position: absolute;
  background: linear-gradient(90deg, #f6f6f4 0%, rgba(246, 245, 244, 0) 100%);
  inset: 0;
  left: 40%;
  right: 30%;
`;

const AWrapper = styled.div`
  font-size: 15px;
  line-height: 24px;

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 31px;
  }
  a {
    color: #039259;
    text-decoration: underline;
  }
`;
const Services: React.FC<{services: any}> = ({services}) => {
  const {t} = useTranslation();
  const [_, dispatch] = useContext(Context);

  const {
    heading,
    heading_secondary,
    button_text,
    button2_text,
    link_text,
    copy_heading_1,
    copy_text_1,
    copy_heading_2,
    copy_text_2,
  } = data.homepage.services;

  const handleBookButton = () => {
    dispatch({type: 'TOGGLE_DRAWER'});
  };

  return (
    <ServicesWrapper>
      <Container>
        <StyledHeading>
          {t(heading)} <span>{t(heading_secondary)}</span>{' '}
        </StyledHeading>
        <ServicesWraper>
          <Copy>
            <strong>{t(copy_heading_1)}</strong>
            <AWrapper dangerouslySetInnerHTML={{__html: t(copy_text_1)}} />
          </Copy>
          {services.map(({node}: any) => {
            const {id, title, background, style, url} = node;

            return (
              <Item key={id}>
                <WhiteBg />
                <WhiteBgTransparent />
                <ImageWrapper>
                  <GatsbyImage image={getImage(background)} />
                </ImageWrapper>
                <ContentWrapper>
                  <Title>{t(title)}</Title>
                  <ButtonGroup>
                    <BookButton
                      onClick={handleBookButton}
                      color={style === 'two' ? 'outline-secondary' : 'primary'}
                      className={style === 'two' ? 'border-200' : ''}
                    >
                      {t(style === 'one' ? button_text : button2_text)}
                    </BookButton>

                    {url && (
                      <TranslatedLink to={url} aria-label="link">
                        <LinkButton>{t(link_text)}</LinkButton>
                      </TranslatedLink>
                    )}
                  </ButtonGroup>
                </ContentWrapper>
              </Item>
            );
          })}
          <div>
            <Copy>
              <strong>{t(copy_heading_2)}</strong>
              <AWrapper>{t(copy_text_2)}</AWrapper>
            </Copy>
            <BookButton
              onClick={handleBookButton}
              color="outline-secondary"
              className="border-200 inline mt-4"
            >
              {t(button2_text)}
            </BookButton>
          </div>
        </ServicesWraper>
      </Container>
    </ServicesWrapper>
  );
};

export default Services;
