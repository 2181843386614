import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../state/store';
import tw, {styled} from 'twin.macro';
import data from '../config/site_data.json';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Button} from './Button';

import SuccessMessage from './SuccessMessage';
import FormConsent from './FormConsent';

import {TranslateUrlsContext} from 'gatsby-plugin-translate-urls';

import {initForm} from '../utils';

const Wrapper = styled.div<{open: boolean}>`
  ${tw`fixed left-0 top-0 w-full h-full z-30 flex justify-center items-center transition-opacity`}

  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
`;

const Overlay = styled.div`
  ${tw`absolute left-0 top-0 w-full h-full`}
  background-color: ${props => props.theme.backgroundOverlay};
`;

const Popup = styled.div`
  ${tw`w-full flex flex-col items-center relative z-30`}
  background-color: ${props => props.theme.colors.defaultBackground};
  box-shadow: 9px 12px 36px rgba(0, 0, 0, 0.1);
  max-width: 348px;
  border-radius: 16px;
  padding: 43px 21px 34px 21px;

  @media ${props => props.theme.screens.md} {
    max-width: 462px;
    padding: 43px 71px;
  }
`;

const Heading = styled.div`
  ${tw`text-secondary`}

  font-size: 31px;
  line-height: 133%;

  text-align: center;
  letter-spacing: -0.01em;
  margin-bottom: 43px;

  @media ${props => props.theme.screens.md} {
    font-size: 33px;
  }
`;

const Form = styled.form`
  ${tw`text-center flex flex-col items-center w-full`}
`;

const FormControl = styled.label`
  ${tw`relative block w-full`}
`;

const Input = styled.input<{error?: boolean}>`
  ${tw` mb-2.5 w-full border border-solid border-100 rounded-lg`}

  height: 56px;
  margin-bottom: 38px;

  &,
  &::placeholder {
    font-weight: 500;
    font-size: 18px;
    line-height: 139.02%;
  }

  &:focus {
    ${tw`border-primary`}
    outline: none;
    box-shadow: none;
  }
`;

const FormButton = styled(Button)`
  height: 52px;
  font-size: 18px;
  line-height: 139.02%;
  letter-spacing: 0.01em;
  padding: 12px 36px 14px 36px;
  margin-bottom: 35px;

  &:disabled {
    background: #eeeeee;
    color: #b9b9b9;

    &:hover {
      opacity: 1;
    }
  }
`;

const ButtonDecline = styled(Button)`
  ${tw`bg-transparent font-medium`}
  color: #CAD1D1;
  padding: 0;
  height: min-content;
  font-size: 18px;
  line-height: 35px;
  width: 272px;
  margin: 0 auto;
`;

const SuccessWrapper = styled.div`
  margin: 30px 0;

  ${tw`text-center`}

  span {
    ${tw`block mt-4 text-200`}
    font-size: 14px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

type NotifyPopupProps = {
  hideWeContactYou?: boolean;
};
const NotifyPopup = ({hideWeContactYou}: NotifyPopupProps) => {
  const [state, dispatch] = useContext(Context);
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [formSubmittedOnce, setFormSubmittedOnce] =
    React.useState<boolean>(false);

  const {t} = useTranslation();
  const {heading, email_placeholder, submit_text, close_text} = data.notify;
  const {send_again} = data.contact.success;

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch({type: 'TOGGLE_NOTIFY'});
    if (formSubmitted) {
      setFormSubmitted(false);
    }
  };

  useEffect(() => {
    const form = document.getElementById('EmailFormNotify');

    form?.addEventListener('kwSubmitted', () => {
      setFormSubmitted(true);
      setFormSubmittedOnce(true);
      setLoading(false);
    });
  }, [formSubmitted]);

  useEffect(() => {
    if (formSubmittedOnce && !formSubmitted) {
      initForm();
    }
  }, [formSubmitted]);

  const {locale} = useContext(TranslateUrlsContext);

  return (
    <Wrapper open={state.isNotifyOpen}>
      <Overlay onClick={handleClose} />
      <Popup>
        <Heading>{t(heading)}</Heading>
        {formSubmitted ? (
          <SuccessWrapper data-id="success-message-discounts-form">
            <SuccessMessage hideWeContactYou={hideWeContactYou} />
            <span onClick={() => setFormSubmitted(false)}>{t(send_again)}</span>
          </SuccessWrapper>
        ) : (
          <Form
            className="kwes-form"
            action="https://kwes.io/api/foreign/forms/YGjoFEuSin4hfXnQEhrB"
            data-kw-no-reload
            id="EmailFormNotify"
            data-kw-lang={locale}
            autoComplete="off"
          >
            <input type="hidden" name="locale" value={locale} />
            <FormControl>
              <Input
                type="email"
                name="email"
                data-kw-rules="required|email"
                placeholder={t(email_placeholder)}
              />
            </FormControl>
            <FormConsent className="mb-4" type="CHECKBOX_FORM" />

            <FormButton type="submit" loading={loading}>
              {t(submit_text)}
            </FormButton>
          </Form>
        )}

        <ButtonDecline onClick={handleClose}>{t(close_text)}</ButtonDecline>
      </Popup>
    </Wrapper>
  );
};

export default NotifyPopup;
