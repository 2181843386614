import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import tw, { styled } from 'twin.macro';

import { Container, Section } from '../Grid';
import { Heading } from '../Typography';

const Wrapper = styled(Section)``;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary`}
  max-width: 600px;

  margin-bottom: 46px;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 60px;
  }

  @media ${props => props.theme.screens.lg} {
    margin-left: 57px;
  }
`;

const Item = styled.a`
  display: flex;
  margin-right: 1.5vw;
  margin-bottom: 1.5vw;
  align-items: center;
`;

const ImageWrapper = styled.div`
  cursor: pointer;
  filter: grayscale(1);
  opacity: 0.5;
  height: auto;
  width: 13vw;
  max-width: 150px;
  min-width: 120px;
  transition: all 0.3s;
  &:hover {
    opacity: 1;
    filter: none;
  }

  @media ${props => props.theme.screens.lg} {
    min-width: 140px;
    width: 14vw;
  }
  @media ${props => props.theme.screens.xl} {
    width: 15vw;
    max-width: 200px;
  }
`;

const Partners = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const OurPartners = () => {
  const {t} = useTranslation();

  const partnersData = useStaticQuery(graphql`
    query {
      partners: allAirtable(filter: {data: {Publish: {eq: true}}}) {
        edges {
          node {
            data {
              Company_name
              Url
              logo {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      quality: 90
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const partners = useMemo(() => {
    if (!Array.isArray(partnersData.partners?.edges)) {
      return [];
    }
    return partnersData.partners.edges.filter(
      p => p?.node?.data?.logo?.localFiles?.[0]?.childImageSharp,
    );
  }, [partnersData]);

  return (
    <Wrapper>
      <Container>
        <StyledHeading>{t('our_partners')}</StyledHeading>
        <Partners>
          {partners.map(({node}: any) => {
            const {data} = node || {};
            const {Company_name, logo, Url} = data || {};
            const imageSrc = logo?.localFiles?.[0].childImageSharp;

            return (
              <Item key={Company_name} href={Url} target="_blank">
                <ImageWrapper>
                  <GatsbyImage image={getImage(imageSrc)} alt={Company_name} />
                </ImageWrapper>
              </Item>
            );
          })}
        </Partners>
      </Container>
    </Wrapper>
  );
};

export default OurPartners;
