import React from 'react';
import { graphql } from 'gatsby';

import config from '../config/site_data.json';
import Layout from '../components/Layout';
import BookingProcess from '../components/Page/home/BookingProcess';
import WorkWithUs from '../components/Page/home/WorkWithUs';
import NewsLetterForm from '../components/Page/home/EmailForm';
import Services from '../components/Page/home/Services';
import Reviews from '../components/Page/home/Reviews/Reviews';
import Hero from '../components/Page/home/Hero';
import OurPartners from '../components/Page/OurPartners';

import BookingBanner from '../components/Page/BookingBanner';
import FAQ from '../components/Page/FAQ';
import NotifyPopup from '../components/NotifyPopup';

import SEO from '../components/Seo';
import { useI18next } from 'gatsby-plugin-react-i18next';
import OurWork from '../components/Page/home/ourwork';
import useIsInView from '../hooks/useIsInView';

const App = ({ data }: any) => {
  const { language, t } = useI18next();
  const servicesRef = React.useRef();
  const [wasServicesInView, setWasInView] = React.useState(false)
  const isServicesVisible = useIsInView(servicesRef);
  React.useEffect(() => {
    if (!wasServicesInView && isServicesVisible) {
      setWasInView(true)
    }
  }, [isServicesVisible])
  const { heading, description } = config.homepage.our_work;
  return (
    <Layout>
      <SEO
        title={t('homepage_seo_title')}
        description={t('homepage_seo_description', { returnObjects: true })}
        lang={language}
        keywords={t('homepage_seo_keywords', { returnObjects: true })}
      />
      <NotifyPopup hideWeContactYou={true} />
      <Hero offers={data.offers.edges} />
      <div ref={servicesRef}>{wasServicesInView && <Services services={data.services.edges} />
      }</div>
      <BookingProcess />
      <WorkWithUs />
      <OurWork
        ourwork={data.ourwork.edges}
        title={heading}
        description={description}
      />
      <Reviews reviews={data.reviews.edges} />
      <BookingBanner
        filename="home-bookingbanner.jpg"
        heading={`save_time_for_happy_things`}
        color="white"
      />
      <FAQ faqs={data.faq.edges} />
      <OurPartners />
      <NewsLetterForm hideWeContactYou={true} />
    </Layout>
  );
};

export default App;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["common", "homepage", "seo_homepage"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    faq: allContentfulFaq(
      sort: {order: ASC, fields: createdAt}
      filter: {pageTag: {eq: "homepage"}, node_locale: {eq: $language}}
    ) {
      edges {
        node {
          childContentfulFaqAnswerTextNode {
            answer
          }
          question
          id
        }
      }
    }
    services: allContentfulServices(
      sort: {fields: createdAt, order: ASC}
      filter: {node_locale: {eq: $language}}
    ) {
      edges {
        node {
          id
          title
          background {
            gatsbyImageData(
              width: 800
              quality: 90
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          style
          url
        }
      }
    }
    ourwork: allContentfulOurWork(
      sort: {order: ASC, fields: createdAt}
      filter: {node_locale: {eq: $language}, category: {eq: "general"}}
    ) {
      edges {
        node {
          id
          category
          images {
            gatsbyImageData(
              width: 800
              quality: 90
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    reviews: allContentfulReviews(
      sort: {order: ASC, fields: createdAt}
      filter: {node_locale: {eq: $language}}
    ) {
      edges {
        node {
          comment {
            comment
          }
          rating
          title
          id
          name
          icon {
            gatsbyImageData(
              width: 800
              quality: 90
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    offers: allContentfulOffers(
      sort: {order: ASC, fields: createdAt}
      filter: {node_locale: {eq: $language}}
    ) {
      edges {
        node {
          id
          title
          promocode
        }
      }
    }
  }
`;
